import styled from "@emotion/styled";
import { UI_BORDER_LIGHT_COLOR_NAME } from "styles/color-consts";
import colors from "styles/colors";
export const StyledProductCardFeatures = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: 0.875rem;
  padding-right: 72px;

  .divider {
    width: 100%;
    padding: 0 60px;
    height: 1px;
    background-color: ${colors[UI_BORDER_LIGHT_COLOR_NAME]};
  }

  .features {
    display: flex;
    align-items: center;
    gap: 16px;

    .feature {
      display: flex;
      gap: 8px;
      align-items: center;

      svg {
        width: 12px;
      }
    }
  }
`;
