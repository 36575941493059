import styled from "@emotion/styled";
const BORDER_COLOR = "#d9d9d9";
export const StyledProductCardContent = styled.div`
  display: flex;
  flex-direction: column;

  .content-title {
    font-size: 2rem;
    line-height: 2.375rem;
    font-weight: 600;
  }

  .use-cases-wrapper {
    padding-top: 12px;
    border-top: 0.5px solid;
    border-color: ${BORDER_COLOR};
  }
`;
