import styled from "@emotion/styled";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH, MACBOOK_PRO_MAX_WIDTH } from "constants/vp-sizes";
import DesktopHeaderConstants from "segments/desktop/header/layouts/components/basic-header/constants";
const HEADER_LOGO_HORIZONTAL_PADDING = DesktopHeaderConstants.HEADER_CONTENT_HORIZONTAL_PADDING;
export const StyledProductCardsSideBySideTextAndAssetComponent = styled.div`
  max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px;
  margin: auto;
  padding: 0 ${HEADER_LOGO_HORIZONTAL_PADDING}px;
  display: flex;
  gap: 48px;
  padding-top: 60px;

  .product-card-component {
    flex: 1;
  }

  @media (max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px) {
    gap: 36px;

    .product-card-component {
      .card-top .product-details {
        .product-card-title .card-title-text {
          display: flex;
          flex-direction: column;
          justify-content: center;
          .main-title {
            font-size: 1rem;
            line-height: 20px;
          }
        }
        .product-card-content .content-title {
          font-size: 1.75rem;
          line-height: 36px;
        }

        .product-card-content .use-cases-wrapper {
          .product-card-use-cases .readonly-use-cases-wrapper {
            max-width: 400px;
            .paragraph-wrapper .paragraph-body {
              font-size: 1rem;
            }
          }
        }
      }

      &.condensed-card {
        .card-top .product-details {
          @media (max-width: ${MACBOOK_PRO_MAX_WIDTH}) {
            .product-card-content {
              .paragraph-wrapper .paragraph-body {
                font-size: 1rem;
              }
              .content-title {
                min-height: 110px;
              }
            }
          }
        }
      }
    }
  }
`;
