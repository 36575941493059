import styled from "@emotion/styled";
import colors from "styles/colors";
import { WHITE_COLOR_NAME } from "styles/color-consts";
import { MAX_DESKTOP_GRID_WIDTH } from "constants/vp-sizes";
export const StyledProductCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin: 0;
  border-radius: 12px;
  background-color: ${colors[WHITE_COLOR_NAME]};
  padding: 72px 0 32px 48px;
  box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.1);

  .product-image {
    .picture-component {
      display: flex;

      img {
        max-width: 100%;
        object-fit: contain;
        max-height: 100%;
        border-bottom-right-radius: 12px;
        border-bottom-left-radius: 12px;
      }
    }
  }

  .card-top {
    display: flex;
    gap: 100px;
    justify-content: space-between;

    .product-details {
      display: flex;
      min-width: fit-content;
      flex-direction: column;
      gap: 32px;
     
      @media (max-width: ${MAX_DESKTOP_GRID_WIDTH}px) {
        min-width: 40%;
      }
    }
  }

  &.condensed-card {
    padding-top: 40px;
    padding-bottom: 0;
    padding-left: 0;

    .product-image {
      display: flex;
      justify-content: center;

      img {
        width: 100%;
        object-fit: contain;
      }
    }

    .card-top {
      display: flex;
      gap: 40px;
      flex-direction: column;
      justify-content: space-between;

      .product-details {
        padding: 0 40px 0 40px;
      }
    }
  }
`;
