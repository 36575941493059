import styled from "@emotion/styled";
import { UI_BORDER_LIGHT_COLOR_NAME, WORK_OS_IRIS_COLOR_NAME } from "styles/color-consts";
import colors from "styles/colors";
import { MACBOOK_PRO_MAX_WIDTH } from "constants/vp-sizes";
export const StyledProductCardUseCases = styled.div`
  @media (min-width: ${MACBOOK_PRO_MAX_WIDTH}) {
    .signup-button {
      height: 40px;
      font-size: 0.875px;
    }
  }

  .readonly-use-cases-wrapper {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
    margin-bottom: 32px;
    margin-top: 24px;

    .readonly-use-case {
      display: flex;
      align-items: center;
      gap: 8px;

      &.work-management {
        svg {
          width: 20px;
          height: 20px;

          path {
            fill: ${colors[WORK_OS_IRIS_COLOR_NAME]};
          }
        }
      }
    }
  }

  .selectable-tags-grid {
    .selectable-tags-button-container {
      justify-content: flex-start;
    }

    .selectable-tags-container-wrapper {
      display: flex;
      gap: 12px;
      flex-wrap: wrap;
      margin-bottom: 32px;
      margin-top: 24px;
      justify-content: flex-start;

      .selectable-tag-wrapper {
        border: 1px solid ${colors[UI_BORDER_LIGHT_COLOR_NAME]};
        margin: 0;
        background-color: transparent;
        padding: 4px 8px;

        &:hover {
          border-color: var(--color);
        }

        &.selected {
          border-color: var(--color);

          .selectable-tag-image-container {
            color: var(--color);
          }
        }

        .selectable-tag-image-container {
          height: 12px;
          width: 12px;
          color: ${colors[UI_BORDER_LIGHT_COLOR_NAME]};

          svg {
            width: 8px;
            height: 6px;
          }
        }
      }
    }
  }
`;
