import styled from "@emotion/styled";
export const StyledProductCardTitle = styled.div`
  display: flex;
  gap: 16px;

  .main-title {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: normal;
  }

  .picture-component {
    img {
      width: 50px;
      object-fit: contain;
    }
  }
`;
